import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import TransferOfTheDay from "./pages/TransferOfTheDay";
import EuroCup from "./pages/EuroCup";

export const pages = {
  Transfer_Of_The_Day: "Transfer Of The Day",
  Euro_Cup: "Euro Cup",
};

const DEFAULT_PAGE = pages.Transfer_Of_The_Day;

function App() {
  return (
    <Router>
      <Routes>
        {/* Default redirect to the first available route */}
        <Route
          path="/"
          element={
            <Navigate replace to={`/${DEFAULT_PAGE.replaceAll(" ", "")}`} />
          }
        />
        {/* Define routes based on your pages array or manually */}
        <Route
          path={`/${pages.Transfer_Of_The_Day.replaceAll(" ", "")}`}
          element={<TransferOfTheDay />}>
          <Route path="random" element={<TransferOfTheDay />} />
        </Route>
        <Route
          path={`/${pages.Euro_Cup.replaceAll(" ", "")}`}
          element={<EuroCup />}>
          <Route path="random" element={<EuroCup />} />
        </Route>
        {/* Define other routes */}
        <Route
          path="*"
          element={
            <Navigate replace to={`/${DEFAULT_PAGE.replaceAll(" ", "")}`} />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;

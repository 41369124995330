import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { keyframes } from "@mui/system";
import ReactConfetti from "react-confetti";
import { useTranslation } from "react-i18next";

const shakeAnimation = keyframes`
    0% { transform: translateX(0px); }
    25% { transform: translateX(-10px); }
    50% { transform: translateX(10px); }
    75% { transform: translateX(-10px); }
    100% { transform: translateX(0px); }
`;

const MultipleAnswersGuessBar = ({ guessPts, guessState, handleSubmit }) => {
  const { t } = useTranslation();
  // Determine message based on the state
  const getMessage = () => {
    if (guessState === "NoPlayerSelected") {
      return t("pleaseSubmitAGuess");
    } else if (guessState === "CorrectGuess") {
      return t("correctGuessPoints", { guessPts } );
    } else if (guessState === "PlayerRevealed") {
      return t("playerRevealedPoints");
    } else if (guessState === "WrongGuess") {
      return t("wrongGuessPoints");
    } else if (guessState === "PlayerAlreadyGuessed") {
      return t("playerAlreadyGuessed");
    }
    return t("pleaseSubmitAGuess");
  };

  return (
    <Box paddingY="12px">
      <Button
        variant="contained"
        disabled={guessState === "CorrectGuess"}
        onClick={handleSubmit}
        sx={{ margin: "10px" }}>
        {t("submit")}
      </Button>
      {guessState === "CorrectGuess" && (
        <ReactConfetti numberOfPieces={1000} recycle={false} />
      )}
      <Typography
        sx={{
          fontSize: "20px",
          color:
            guessState === "CorrectGuess"
              ? "green"
              : guessState.startsWith("Wrong") ||
                guessState === "PlayerAlreadyGuessed"
              ? "red"
              : "inherit",
          ...((guessState === "WrongGuess" ||
            guessState === "PlayerAlreadyGuessed") && {
            animation: `${shakeAnimation} 0.5s ease-in-out`,
          }),
        }}>
        {getMessage()}
      </Typography>
      <Typography variant="h6" sx={{ textAlign: "right", fontWeight: "bold" }}>
        {t("currentPoints")} {guessPts}
      </Typography>
    </Box>
  );
};

export default MultipleAnswersGuessBar;

import React, { useState, useEffect } from "react";
import { TableCell as MuiTableCell, Button, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  PlayerPortrait,
  PlayerNationality,
  ClubPortrait,
} from "./PlayerDetail";
import { MAX_GUESS_COUNT } from "../pages/TransferOfTheDay";

const CustomTableCell = ({
  header,
  playerData,
  guessCount,
  onReveal,
  isInitiallyRevealed = false,
  showRevealButton = true,
  defaultColor = "#FBF6EE",
  revealColor = "#65B741",
}) => {
  const [isRevealed, setIsRevealed] = useState(isInitiallyRevealed);
  const { t } = useTranslation();

  useEffect(() => {
    if (isInitiallyRevealed && !isRevealed) {
      setIsRevealed(true);
    }
  }, [isInitiallyRevealed, isRevealed]);

  const handleRevealClick = () => {
    if (!isRevealed) {
      setIsRevealed(true);
      if (onReveal) onReveal();
    }
  };

  // Common reveal button that could be conditionally rendered
  const revealButton =
    !isRevealed && showRevealButton && guessCount < MAX_GUESS_COUNT ? (
      <Button
        variant="contained"
        size="small"
        onClick={handleRevealClick}
        sx={{ padding: "5px", marginTop: "5px" }}>
        {t("reveal")}
      </Button>
    ) : null;

  // Determine the content based on the header and reveal state
  let content;
  let specialContent;
  if (!isRevealed && guessCount < MAX_GUESS_COUNT) {
    content = "???";
  } else if (!header || header === "") {
    content = "N/A";
  } else {
    switch (header) {
      case "playerName":
        specialContent = <PlayerPortrait playerData={playerData} />;
        break;
      case "nationality":
        specialContent = (
          <PlayerNationality playerData={playerData.nationality} />
        );
        break;
      case "team1":
        specialContent = <PlayerNationality playerData={playerData.team1} />;
        break;
      case "team2":
        specialContent = <PlayerNationality playerData={playerData.team2} />;
        break;
      case "fromClub":
        specialContent = (
          <ClubPortrait
            playerData={[playerData.fromClub, playerData.fromClubUrl]}
          />
        );
        break;
      case "toClub":
        specialContent = (
          <ClubPortrait
            playerData={[playerData.toClub, playerData.toClubUrl]}
          />
        );
        break;
      case "club":
        specialContent = (
          <ClubPortrait playerData={[playerData.club, playerData.clubUrl]} />
        );
        break;
      case "transferFee":
        content =
          playerData["fee"] === "-" ? "Not Available" : playerData["fee"];
        break;
      case "marketvalues":
        content =
          playerData["marketValue"] === "-"
            ? "Retired or Not Available"
            : playerData["marketValue"];
        break;
      default:
        content = playerData[header];
    }
  }

  return (
    <MuiTableCell
      sx={{
        backgroundColor:
          isRevealed || guessCount >= MAX_GUESS_COUNT
            ? revealColor
            : defaultColor,
      }}>
      {specialContent
        ? specialContent
        : header !== "" && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center">
              {content}
              {revealButton}
            </Box>
          )}
    </MuiTableCell>
  );
};

export default CustomTableCell;

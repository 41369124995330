// components/ResultModal.js

import React from "react";
import { Modal, Box, Typography, Button, Divider } from "@mui/material";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
} from "react-share";
import { useNavigate, useLocation } from "react-router-dom";
import ReactConfetti from "react-confetti";
import CountdownTimer from "../components/CountdownTimer.js";
import "../styles/styles.css";
import { useTranslation } from 'react-i18next';

const ResultModal = ({ open, message, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const currentUrl = window.location.href;
  const sharingText =
    "I just completed a game on Futdle.io! Check it out with me!";

  // Handler for navigation buttons
  const handleNewRandomGame = (targetPath) => {
    if (location.pathname === targetPath) {
      // Reload the page if already at the target path
      window.location.reload();
    } else {
      // Navigate to the target path if not already there
      navigate(targetPath);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(4px)", // Optional: adds a slight blur effect to the backdrop
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black backdrop
      }}>
      <Box className="modal-container">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}></Box>

        <Typography
          variant="h5"
          sx={{
            fontSize: "22px",
            fontWeight: "bold",
            marginBottom: "20px",
            whiteSpace: "pre-line",
          }}>
          {message}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            marginBottom: "15px",
          }}>
          <Typography variant="h6">{t('shareVia')}</Typography>
          {/* Facebook Share Button */}
          <FacebookShareButton url={currentUrl} quote={sharingText}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          {/* Twitter (X) Share Button */}
          <TwitterShareButton url={currentUrl} title={sharingText}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          {/* Reddit Share Button */}
          <RedditShareButton url={currentUrl} title={sharingText}>
            <RedditIcon size={32} round />
          </RedditShareButton>

          {/* LinkedIn Share Button */}
          <LinkedinShareButton url={currentUrl} title={sharingText}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </Box>

        <Divider></Divider>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            marginTop: "15px",
            marginBottom: "15px",
          }}>
          <CountdownTimer></CountdownTimer>
        </Box>

        {/* New buttons for navigating to a random game or different page */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            marginBottom: "10px",
          }}>
          <Typography>{t('newRandomGame')}: </Typography>
          <Button
            onClick={() => handleNewRandomGame("/TransferOfTheDay/random")}
            variant="contained"
            sx={{
              backgroundColor: "#6A5ACD",
              color: "#fff",
              fontSize: "12px",
            }}>
            {t('newRandomGame')}
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            marginBottom: "10px",
          }}>
          <Typography>{t('euroCupTrivia')}: </Typography>
          <Button
            onClick={() => handleNewRandomGame("/EuroCup")}
            variant="contained"
            sx={{
              backgroundColor: "#FF6347",
              color: "#fff",
              fontSize: "12px",
            }}>
            {t('euroCupTrivia')}
          </Button>
        </Box>

        <Button
          onClick={onClose}
          variant="contained"
          sx={{ mt: 1, mb: 1, fontFamily: "Arial" }}>
          {t('close')}
        </Button>

        {message.includes("Congratulations!") && (
        <ReactConfetti numberOfPieces={1000} recycle={false} />
      )}
      </Box>
    </Modal>
  );
};

export default ResultModal;

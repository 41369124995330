import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "@mui/material";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  return (
    <ButtonGroup variant="text" aria-label="language switcher">
      <Button onClick={() => i18n.changeLanguage("en")}>
        <img
          src="/img/national_flags/flag-of-England.png"
          alt="English"
          width="25"
          height="15"
        />
      </Button>
      <Button onClick={() => i18n.changeLanguage("es")}>
        <img
          src="/img/national_flags/flag-of-Spain.png"
          alt="Español"
          width="25"
          height="15"
        />
      </Button>
    </ButtonGroup>
  );
};

export default LanguageSwitcher;

import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { keyframes } from "@mui/system";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer"; // Soccer ball icon
import CancelOutlined from "@mui/icons-material/CancelOutlined"; // For used guesses, or you can use a different icon
import { useTranslation } from "react-i18next";
import { MAX_GUESS_COUNT } from "../pages/TransferOfTheDay";

const shakeAnimation = keyframes`
    0% { transform: translateX(0px); }
    25% { transform: translateX(-10px); }
    50% { transform: translateX(10px); }
    75% { transform: translateX(-10px); }
    100% { transform: translateX(0px); }
`;

const SingleAnswerGuessBar = ({ guessesUsed, guessState, handleSubmit }) => {
  const { t } = useTranslation();
  // Helper function to generate guess icons
  const renderGuessIcons = () => {
    // Adjust guessesUsed if it's greater than 10
    const effectiveGuessesUsed =
      guessesUsed > 10 ? guessesUsed - 10 : guessesUsed;

    return Array.from({ length: MAX_GUESS_COUNT }, (_, index) =>
      index < effectiveGuessesUsed ? (
        <CancelOutlined key={index} color="disabled" fontSize="large" />
      ) : (
        <SportsSoccerIcon key={index} color="action" fontSize="large" />
      )
    );
  };

  // Determine message based on the state
  const getMessage = () => {
    if (guessesUsed === 0 || guessState === "NoPlayerSelected") {
      return t("pleaseSubmitAGuess");
    } else if (guessesUsed === MAX_GUESS_COUNT) {
      return t("noMoreGuessesLeft");
    } else if (guessState === "CorrectGuess") {
      const guesses = guessesUsed - 10;
      return t("correctGuess", { guesses });
    } else if (guessState === "FieldRevealed") {
      return t("fieldRevealed");
    } else if (guessState === "WrongGuess") {
      return t("wrongGuess");
    } else if (guessState === "PlayerAlreadyGuessed") {
      return t("playerAlreadyGuessed");
    }
    return null;
  };

  return (
    <Box paddingY="12px">
      <Button
        variant="contained"
        disabled={guessesUsed >= MAX_GUESS_COUNT}
        onClick={handleSubmit}
        sx={{ margin: "10px" }}>
        {t("submit")}
      </Button>
      <Typography
        sx={{
          fontSize: "20px",
          color:
            guessState === "CorrectGuess"
              ? "green"
              : guessState.startsWith("Wrong") ||
                guessState === "PlayerAlreadyGuessed" ||
                guessesUsed === MAX_GUESS_COUNT
              ? "red"
              : "inherit",
          ...((guessState === "WrongGuess" ||
            guessState === "PlayerAlreadyGuessed") && {
            animation: `${shakeAnimation} 0.5s ease-in-out`,
          }),
        }}>
        {getMessage()}
      </Typography>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography marginRight="8px">{t("guessesLeft")} </Typography>
        {renderGuessIcons()}
      </Box>
    </Box>
  );
};

export default SingleAnswerGuessBar;

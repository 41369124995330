import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Tab,
  Tabs,
  Modal,
  styled,
} from "@mui/material";
import Marquee from "react-fast-marquee";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"; // Icon for "How to play"
import LocalCafeIcon from "@mui/icons-material/LocalCafe"; // Icon for "Buy me a coffee"
import { useTranslation } from "react-i18next";
import { pages } from "../App.js";
import LanguageSwitcher from "./LanguageSwitcher";

const LogoImage = styled("img")({
  // Adjust the styling as necessary
  maxWidth: "200px", // Example height, adjust as needed
  marginRight: "16px",
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600, // Adjust width as necessary
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// TabPanel component to show the content of each tab
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// Function to generate tab panel properties
function tabStyleProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: {
      // border: "1px solid black",
      margin: "0 5px",
    },
  };
}

export const NavigationBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openTutorialModal, setOpen] = useState(false); // State to manage modal visibility
  const [tabIndex, setTabIndex] = useState(0);
  const [openKofiModal, setOpenKofi] = useState(false); // State to manage modal visibility

  // Function to handle opening the modal
  const handleOpenTutorial = () => setOpen(true);
  const handleOpenKofi = () => setOpenKofi(true);
  // Function to handle closing the modal
  const handleCloseTutorial = () => setOpen(false);
  const handleCloseKofi = () => setOpenKofi(false);

  const pageKeys = Object.keys(pages);
  const [tabValue, setValue] = useState(0);

  const { t } = useTranslation();
  const TotDHelpParagraphs = t("TotDHelpParagraphs");
  const EuroCupHelpParagraphs = t("EuroCupHelpParagraphs");

  useEffect(() => {
    const currentPage = location.pathname.substring(1); // remove leading '/'
    const pageIndex = pageKeys.findIndex(
      (key) =>
        key.replaceAll("_", "").toLowerCase() === currentPage.toLowerCase()
    );
    if (pageIndex !== -1) {
      setValue(pageIndex);
    }
  }, [location.pathname, pageKeys]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/${pageKeys[newValue].replaceAll("_", "")}`);
    window.location.reload();
  };

  const handleHelpTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "black",
      }}>
      <Toolbar>
        {/* Replace MenuIcon with your logo */}
        <Link to="/">
          <LogoImage src="/futdle-logo-color.png" alt="Futdle Logo" />
        </Link>

        {/* Rest of the items are pushed to the right */}
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            textColor="inherit"
            TabIndicatorProps={{ style: { backgroundColor: "white" } }}>
            {pageKeys.map((key, index) => (
              <Tab
                key={key}
                label={pages[key]}
                sx={{
                  color: "white",
                  border: "1px solid white",
                  margin: "0 5px",
                }}
              />
            ))}
          </Tabs>
        </Box>

        {/* IconButton with How to play icon */}
        <IconButton
          aria-label="How to play"
          size="large"
          color="primary"
          onClick={handleOpenTutorial}>
          <HelpOutlineIcon />
        </IconButton>
        {/* Define the Modal component */}
        <Modal
          open={openTutorialModal}
          onClose={handleCloseTutorial}
          aria-labelledby="how-to-play-title"
          aria-describedby="how-to-play-description">
          <Box sx={modalStyle}>
            <Tabs
              value={tabIndex}
              onChange={handleHelpTabChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="How to play"
              centered>
              <Tab label="Transfer of the Day" {...tabStyleProps(0)} />
              <Tab label="Euro Cup Trivia" {...tabStyleProps(1)} />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <Typography
                variant="h4"
                component="h2"
                align="center"
                gutterBottom>
                {t("howToPlay")}<br></br>
                {t("transferOfTheDay")}
              </Typography>
              {TotDHelpParagraphs.map((text, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", alignItems: "start", mt: 2 }}>
                  <StyledParagraph key={index} text={text} />
                </Box>
              ))}
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <Typography
                variant="h4"
                component="h2"
                align="center"
                gutterBottom>
                {t("howToPlay")}<br></br>
                {t("euroCupTrivia")}
              </Typography>
              {EuroCupHelpParagraphs.map((text, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", alignItems: "start", mt: 2 }}>
                  <StyledParagraph key={index} text={text} />
                </Box>
              ))}
            </TabPanel>
          </Box>
        </Modal>

        {/* IconButton with Buy me a coffee icon */}
        <IconButton
          aria-label="Buy me a coffee"
          size="large"
          color="primary"
          onClick={handleOpenKofi}>
          <LocalCafeIcon />
        </IconButton>
        {/* Define the Modal component for Ko-fi from https://ko-fi.com/Manage/donation-widget-setup */}
        <Modal
          open={openKofiModal}
          onClose={handleCloseKofi}
          aria-labelledby="how-to-play-title"
          aria-describedby="how-to-play-description">
          <Box sx={modalStyle}>
            <iframe
              id="kofiframe"
              src="https://ko-fi.com/futdleio/?hidefeed=true&widget=true&embed=true&preview=true"
              style={{
                border: "none",
                width: "100%",
                padding: "4px",
                background: "#f9f9f9",
              }}
              height="680"
              title="futdleio"></iframe>
          </Box>
        </Modal>
        <LanguageSwitcher />
      </Toolbar>
    </AppBar>
  );
};

const StyledParagraph = ({ text }) => {
  // Regular expression to match the delimiters: ?, ,, ., :, or --
  const regex = /(.*?)(\?|,|\.|:|!|--)(.*)/;
  const match = text.match(regex);

  // Include the delimiter with the first part and trim any whitespace
  const firstPart = `${match[1]}${match[2]}`;
  const restPart = match[3];
  return (
    <Typography variant="body1" fontFamily="sans-serif" gutterBottom>
      • <strong>{firstPart}</strong>
      {restPart}
    </Typography>
  );
};

export const AnnouncementBar = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box
        sx={{
          overflow: "hidden",
          width: "100%", // Ensure the bar spans the entire width
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "2rem", // Adjust the height as needed
          backgroundColor: "secondary.dark", // Or any color from your theme
        }}>
        {/* Use the MarqueeText styled component */}
        <Marquee speed={100} direction="right" gradient={true}>
          <Typography
            sx={{
              padding: 1,
              textAlign: "center",
              fontStyle: "italic",
              fontSize: "16px",
              backgroundColor: "secondary.dark",
            }}>
            {t("announcement")}
          </Typography>
        </Marquee>
      </Box>
    </>
  );
};
